import { EvButton, BUTTON_TYPES, EvSpinner, TOAST_STATUS, EvMainTitle } from '@evinced-private/ui-common';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { IUserToTenant } from '../../types/User';
import UserService from '../../services/UserService';
import RoutesHelper from '../../helpers/RoutesHelper';
import SetPermissions from '../../components/create-user-invitation-popup/bulk-invitations-steps/SetPermissions';
import UserInfo from './user-info/UserInfo';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { INotificationsContext } from '../../providers/notificationsProvider/NotificationsContext';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { useNavigation } from '../../providers/navigationProvider/NavigationProvider';
import './EditUserPage.scss';

interface ILocationState {
	pathname: string;
	state: {
		user: IUserToTenant;
	};
}

const EditUserPage: FC = () => {
	const history = useHistory();
	const location: ILocationState = useLocation();
	const { currentBreadcrumbs, setCurrentBreadcrumbs } = useNavigation();
	const notificationsContext: INotificationsContext = useNotifications();
	const { getFullRoleItem } = useUserTenant();

	const user = (location?.state?.user as IUserToTenant) || null;

	const [userData, setUserData] = useState<IUserToTenant>(user);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onUserDataChange = (updatedUserData: IUserToTenant): void => {
		const updatedUser = { ...userData, user: { ...updatedUserData.user } };
		setUserData(updatedUser);
	};

	const onUserPermissionChange = (updatedUserPermission: string): void => {
		const newType = getFullRoleItem(updatedUserPermission);

		const updatedUser = { ...userData, role: newType };
		setUserData(updatedUser);
	};

	const disableSaveButton = useMemo(() => {
		if (!userData) {
			return false;
		}
		const { name, surname, email } = userData.user;
		return !name || !surname || !email;
	}, [userData]);

	const onCancel = (): void => {
		history.push(RoutesHelper.getActiveUsers());
	};

	const onSave = async (): Promise<void> => {
		try {
			setIsLoading(true);
			await UserService.updateUserData(userData);
			notificationsContext.toast({
				announcement: 'Details updated',
				status: TOAST_STATUS.SUCCESS,
				show: true
			});
			history.push(RoutesHelper.getActiveUsers());
		} catch (error) {
			notificationsContext.toast({
				announcement: 'Save failed. Please retry',
				status: TOAST_STATUS.FAIL,
				show: true
			});
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!user) {
			notificationsContext.toast({
				announcement: 'User does not exist',
				status: TOAST_STATUS.FAIL,
				show: true
			});
			history.push(RoutesHelper.getActiveUsers());
		}
		if (user && currentBreadcrumbs) {
			const prevBreadcrumbs = [...currentBreadcrumbs];
			const newBreadcrumb = {
				id: `edit-user-${userData.user.name}-${userData.user.surname}`,
				url: RoutesHelper.getEditUserPath(user.id),
				title: `${userData.user.name} ${userData.user.surname}`
			};
			/**
			 * In a case of the 'Edit User' page, we don't want to show the id of the user
			 * in the breadcrumbs but the full name.
			 * When we navigate to the page, the initial breadcrumbs are
			 * Manage users > Active users >> 'some-id'.
			 * Therefore, we need to remove the last item and replace it with the item with
			 * the name.
			 */
			const lastBreadcrumb = prevBreadcrumbs[prevBreadcrumbs.length - 1];

			if (lastBreadcrumb?.url === newBreadcrumb?.url && lastBreadcrumb.id !== newBreadcrumb.id) {
				prevBreadcrumbs.pop();
				const newBreadcrumbs = [...prevBreadcrumbs, lastBreadcrumb];
				setCurrentBreadcrumbs(newBreadcrumbs);
			}
			// Check if the new breadcrumb is different from the last breadcrumb
			if (
				prevBreadcrumbs.length === 0 ||
				prevBreadcrumbs[prevBreadcrumbs.length - 1].id !== newBreadcrumb.id
			) {
				const newBreadcrumbs = [...prevBreadcrumbs, newBreadcrumb];
				setCurrentBreadcrumbs(newBreadcrumbs);
			}
		}
	}, [user, setCurrentBreadcrumbs, currentBreadcrumbs, history, notificationsContext, userData]);

	if (isLoading) {
		return <EvSpinner />;
	}

	return (
		<div className="edit-user">
			<EvMainTitle>Edit Details</EvMainTitle>
			{userData && (
				<div className="edit-user-content">
					<UserInfo user={user} onUserDataChange={onUserDataChange} />
					<SetPermissions
						handleChange={onUserPermissionChange}
						userPermission={userData?.role.type}
						userEmail={userData?.user.email}
						isStepper={false}
						className="user-type"
					/>
				</div>
			)}

			<div className="edit-user-actions">
				<EvButton
					type={BUTTON_TYPES.SECONDARY_WITH_TRANSPARENT_BG}
					title="Cancel"
					onClick={onCancel}
				>
					Cancel
				</EvButton>
				<EvButton
					type={BUTTON_TYPES.PRIMARY}
					title="Save"
					onClick={onSave}
					disabled={disableSaveButton}
				>
					Save
				</EvButton>
			</div>
		</div>
	);
};

export default EditUserPage;
