import ReactGA, { EventArgs } from 'react-ga';
import localStorageApi from '../../api/LocalStorageApi';
import logger from '../Logger';

interface IHasUserId {
	userId: string;
}

const analyticsCode = process.env.GA_CODE;

let analyticsInitialized = false;
let currentUser = null;

function initAnalytics(): void {
	try {
		ReactGA.initialize(analyticsCode);
		// Once we have a user ID we should init it here: ReactGA.set({ userId });
		const user = localStorageApi.getUserFromLocalStorage();
		if (user) {
			const { userId } = user;
			ReactGA.set({ userId });
		}
		analyticsInitialized = true;
		logger.debug('analytics was initialized');
	} catch (err) {
		logger.error('Could not initialize analytics service. Got error:', err);
	}
}

function fireEvent(args: EventArgs): void {
	if (!analyticsInitialized) {
		logger.warn('Couldnt fire analytics event, analytics initialization failed.');
		throw new Error('Analytics service was called without initialization');
	}
	ReactGA.event(args);
}

function pageView(path): void {
	if (!analyticsInitialized) {
		logger.error('Couldnt call pageview, analytics initialization failed.');
		throw new Error('Analytics service was called without initialization');
	}
	ReactGA.pageview(path);
}

function getCurrentUser(): IHasUserId {
	return currentUser;
}

function setCurrentUser(user: IHasUserId): void {
	currentUser = user;
	const { userId } = currentUser;
	localStorageApi.setGoogleAnalyticsUser(currentUser);
	ReactGA.set({ userId });
}

export default { initAnalytics, fireEvent, pageView, getCurrentUser, setCurrentUser };
