export enum DevelopmentToggles {
	ENABLE_CREATE_TENANT = 'ENABLE_CREATE_TENANT',
	SHOW_NEW_PRODUCTS = 'SHOW_NEW_PRODUCTS',
	ENABLE_SYNC = 'ENABLE_SYNC'
}

export const TOGGLES_DETAILS = [
	{
		name: DevelopmentToggles.ENABLE_CREATE_TENANT,
		label: 'Enable creation of a new tenant instead of selecting one you are invited to'
	},
	{
		name: DevelopmentToggles.SHOW_NEW_PRODUCTS,
		label: 'Show the new products: Design Assistant and Code Assistant'
	},
	{
		name: DevelopmentToggles.ENABLE_SYNC,
		label: 'Show link to wfa platform'
	}
];

const getToggle = (toggle: DevelopmentToggles): boolean => {
	const value = localStorage.getItem(toggle);
	// cast the string value to it's boolean equivalent
	return value === 'true';
};

const setToggle = (toggle: DevelopmentToggles, value: string): void => {
	localStorage.setItem(toggle, value);
};

const removeToggle = (toggle: DevelopmentToggles): void => {
	localStorage.removeItem(toggle);
};
export default { getToggle, setToggle, removeToggle };
