import { IUserInvitation } from '../../types/UserInvitation';
import { IOpenInvitation } from '../../types/OpenInvitation';
import { PendingInvitation, INVITATION_TYPE } from '../../types/PendingInvitation';

export const removeOldDuplicates = (
	userInvites: IUserInvitation[],
	openInvites: IOpenInvitation[]
): PendingInvitation[] => {
	const olderThanAnyUserInvite = new Date(-1);
	const pendingInvites: PendingInvitation[] = (openInvites || []).map((i) => (
		{ type: INVITATION_TYPE.OPEN, invitation: i, createdTime: olderThanAnyUserInvite }
	));
	userInvites.forEach((i) => {
		const index = pendingInvites.findIndex((p) => p.invitation.tenantId === i.tenantId);
		const pendingInvite = {
			type: INVITATION_TYPE.EMAIL,
			invitation: i,
			createdTime: new Date(i.createdTime)
		};
		if (index === -1) {
			pendingInvites.push(pendingInvite);
		} else if (pendingInvites[index].createdTime.getTime() < pendingInvite.createdTime.getTime()) {
			pendingInvites[index] = pendingInvite;
		}
	});
	return pendingInvites.sort((i1, i2) => i2.createdTime.getTime() - i1.createdTime.getTime());
};
