import { EvLink } from '@evinced-private/ui-common';
import React from 'react';
import classNames from 'classnames';
import './ContactSupportMailLink.scss';

const SUPPORT_EMAIL = 'support@evinced.com';

const ContactSupportMailLink = ({ linkText = null, className = null }): JSX.Element => (
	<EvLink
		className={classNames('contact-us-link', className)}
		url={`mailto:${SUPPORT_EMAIL}`}
		linkText={linkText || SUPPORT_EMAIL}
	/>
);

export default ContactSupportMailLink;
