import { withAuthenticationRequired } from '@auth0/auth0-react';
import { EvSpinner } from '@evinced-private/ui-common';
import React from 'react';
import InvitationTokenHelper from '../../helpers/InvitationTokenHelper';
import AuthToken from './AuthToken';

const userEmail = InvitationTokenHelper.getEmailFromUrl();

export default withAuthenticationRequired(AuthToken, {
	onRedirecting: () => {
		return <EvSpinner />;
	},
	loginOptions: {
		authorizationParams: {
			screen_hint: 'signup',
			...(userEmail ? { login_hint: userEmail } : {})
		}
	}
});
