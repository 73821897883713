import React, { FC, useEffect, useState } from 'react';
import { EvSwitch, EvPopup } from '@evinced-private/ui-common';
import DevTogglesService, { TOGGLES_DETAILS } from '../../services/DevTogglesService';
import { PRODUCT_HUB_APP_ID } from '../../consts/dom-consts';
import './DevTogglesPopup.scss';

interface IDevTogglesPopup {
	closePopup: () => void;
	isOpen: boolean;
}
const DevTogglesPopup: FC<IDevTogglesPopup> = ({ closePopup, isOpen }: IDevTogglesPopup) => {
	const [toggles, setToggles] = useState({});

	const setTogglesState = (): void => {
		const newState = {};
		TOGGLES_DETAILS.forEach((toggle) => {
			const state = DevTogglesService.getToggle(toggle.name);
			newState[toggle.name] = !!state;
		});
		setToggles(newState);
	};

	useEffect(() => {
		setTogglesState();
	}, []);

	const onChange = (type) => {
		return (state) => {
			setToggles({ ...toggles, [type]: state });
		};
	};

	const saveChanges = (): void => {
		TOGGLES_DETAILS.forEach((toggle) => {
			if (toggles[toggle.name]) {
				DevTogglesService.setToggle(toggle.name, 'true');
			} else {
				DevTogglesService.removeToggle(toggle.name);
			}
		});
	};

	const onClose = (): void => {
		setTogglesState();
		closePopup();
	};

	const buttons = [
		{
			title: 'Cancel',
			onClick: onClose,
			secondary: true
		},
		{
			title: 'Save',
			onClick: () => {
				saveChanges();
				closePopup();
			}
		}
	];
	return (
		<EvPopup
			appElement={PRODUCT_HUB_APP_ID}
			title="Dev Toggles"
			isControlled
			isOpen={isOpen}
			onClose={onClose}
			buttons={buttons}
			popupWidth={580}
		>
			<div className="popup-form">
				{TOGGLES_DETAILS.map((toggle) => {
					return (
						<div className="popup-form-row" key={toggle.name}>
							<EvSwitch
								label={toggle.label}
								checked={toggles[toggle.name]}
								onChange={onChange(toggle.name)}
							/>
						</div>
					);
				})}
			</div>
		</EvPopup>
	);
};

export default DevTogglesPopup;
