import { createAuth0Client, Auth0Client } from '@auth0/auth0-spa-js';
import decode from 'jwt-decode';
import localStorageApi from '../api/LocalStorageApi';
import RoutesHelper from '../helpers/RoutesHelper';

const NO_USER_EMAIL = 'NO_USER_EMAIL';
const NO_USER_ACCOUNT = 'NO_USER_ACCOUNT';
const SAML_PROVIDER_KEY = 'samlp';

let auth0Client: Auth0Client = null;

const getUserId = (authUserId: string): string => {
	return authUserId.split('|')[1];
};

const getAccessToken = (): string => {
	return localStorageApi.getAccessToken();
};

const getIdToken = (): string => {
	return localStorageApi.getIdToken();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const decodeToken = (token: string): any => {
	if (token) {
		return decode(token);
	}
	return null;
};

const getEvincedUserIdFromIdToken = (idToken: string): string => {
	if (!idToken) {
		return null;
	}
	const decodedToken = decodeToken(idToken);
	const domainEntity = decodedToken[`https://${process.env.AUTH0_EVINCED_APP_DOMAIN}/user_id`];
	return domainEntity ? getUserId(domainEntity) : null;
};

const getUserAuthProviderFromIdToken = (idToken: string): string => {
	if (!idToken) {
		return null;
	}
	const decodedToken = decodeToken(idToken);
	const domainEntity = decodedToken[`https://${process.env.AUTH0_EVINCED_APP_DOMAIN}/provider`];
	return domainEntity;
};

const getEvincedUserId = (): string => {
	return getEvincedUserIdFromIdToken(getIdToken());
};

const isUserAuthProviderSaml = (): boolean => {
	return getUserAuthProviderFromIdToken(getIdToken()) === SAML_PROVIDER_KEY;
};

const isTokenExpired = (token: string): boolean => {
	try {
		const decoded = decodeToken(token);
		if (decoded.exp <= Date.now() / 1000) {
			// Checking if a specific token had expired
			return true;
		}
		return false;
	} catch (err) {
		return true;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AuthenticationResult = { accessToken: string; idToken: string; appState: any };

const setTokens = (authResult: AuthenticationResult): void => {
	localStorageApi.setAccessToken(authResult.accessToken);
	localStorageApi.setIdToken(authResult.idToken);
};

const getLoginAbsoluteUrl = (): string => {
	const { protocol, host } = window.location;
	const redirectToUrl = `${protocol}//${host}${RoutesHelper.getLoginPath()}`;
	return redirectToUrl;
};

const loginUrl = getLoginAbsoluteUrl();

const getAuth0Client = async (): Promise<Auth0Client> => {
	if (!auth0Client) {
		auth0Client = await createAuth0Client({
			domain: process.env.AUTH0_EVINCED_DOMAIN,
			clientId: process.env.AUTH0_CLIENT_ID,
			authorizationParams: {
				audience: process.env.AUTH0_EVINCED_API_AUDIENCE
			}
		});
	}
	return auth0Client;
};

const validateSession = (authResult): AuthCheckAndRevalidateReponse => {
	if (authResult) {
		// if the token is as the one existing in local storage, use it.
		const localstorageUserId = getEvincedUserId();
		const auth0ResponseUserId = getEvincedUserIdFromIdToken(authResult.idToken);
		if (localstorageUserId === auth0ResponseUserId) {
			setTokens(authResult);
			return { auth0Result: authResult, didUserSwitchAccounts: false };
		}
		// user's session is valid, but it's different from the one existing in localstorage
		// this means that the user has switched account.
		// We'll remove the data from localstorage and reload app
		setTokens(authResult);
		return { auth0Result: authResult, didUserSwitchAccounts: true };
	}

	// got here if the user's token has expired/logged out,
	localStorageApi.removeAuthenticationTokens();
	return null;
};

const universalTokenSet = async (): Promise<AuthCheckAndRevalidateReponse> => {
	const auth0Client = await getAuth0Client();
	let authResult;
	try {
		const accessToken = await auth0Client.getTokenSilently({ cacheMode: 'off' });
		authResult = {
			accessToken,
			idToken: accessToken,
			appState: null
		};
	} catch (err) {
		console.log(`Failed to get token: ${err}`);
	}
	return validateSession(authResult);
};

type AuthCheckAndRevalidateReponse = {
	// results from auth0
	auth0Result: AuthenticationResult;
	// true iff the user related to Auth0's cookie session
	// is different than the one stored in localstorage
	didUserSwitchAccounts: boolean;
};

const checkAndRevalidateSession = async (): Promise<AuthCheckAndRevalidateReponse> => {
	const sessionValidation = await universalTokenSet();
	return sessionValidation;
};

/**
 * @returns true iff user has a valid token in local storage
 */
const isLoggedIn = (): boolean => {
	// Checks if there is a saved token and if it's still valid
	const token = getAccessToken(); // Getting token from local storage
	return !!token && !isTokenExpired(token); // handwaiving here
};

const logout = async (): Promise<void> => {
	// Clear user tokens from local storage
	localStorageApi.removeValuesOnLogout();

	const auth0Client = await getAuth0Client();
	await auth0Client.logout({ logoutParams: { returnTo: loginUrl } });
};

const getProfile = (): { name: string } => decodeToken(getIdToken());

const extractUserEmailFromToken = (decodedToken: { name: string }): string => {
	// The 'https://{app_domain}email' key on the access token relay on auth0 dashboard configuration rule
	// At Dashboard -> Auth Pipeline -> 'Add app-metadata to IDToken and accessToken'
	return (
		decodedToken?.name ||
		decodedToken?.[`https://${process.env.AUTH0_EVINCED_APP_DOMAIN}/email`] ||
		NO_USER_EMAIL
	);
};

const getUserEmail = (): string => {
	return extractUserEmailFromToken(getProfile());
};

const getUserEmailDomain = (): string => {
	const userEmail = extractUserEmailFromToken(getProfile());
	if (userEmail !== NO_USER_EMAIL) {
		const emailDomainIndex = userEmail.indexOf('@');
		if (emailDomainIndex !== -1) {
			return userEmail.substr(emailDomainIndex + 1);
		}
	}
	return NO_USER_ACCOUNT;
};

const getUserAccountId = (): string => {
	const userEmail = extractUserEmailFromToken(getProfile());
	if (userEmail !== NO_USER_EMAIL) {
		const emailDomainIndex = userEmail.indexOf('@');
		if (emailDomainIndex !== -1) {
			return userEmail.substr(emailDomainIndex + 1);
		}
	}

	return NO_USER_ACCOUNT;
};

const isEvincedUser = (): boolean => {
	const userEmailDomain = getUserEmailDomain();
	return userEmailDomain === 'evinced.com';
};

export default {
	isLoggedIn,
	setTokens,
	logout,
	getAccessToken,
	getEvincedUserId,
	checkAndRevalidateSession,
	getUserEmail,
	getUserAccountId,
	isEvincedUser,
	isUserAuthProviderSaml
};
