import { EvSpinner } from '@evinced-private/ui-common';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MAIN_TITLE } from 'src/consts/products-consts';
import ContactSupportMailLink from '../../components/contact-support/ContactSupportMailLink';
import Header from '../../components/header/Header';
import InvitationTokenHelper, {
	EMAIL_QUERY_PARAM,
	TOKEN_QUERY_PARAM
} from '../../helpers/InvitationTokenHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import Logger from '../../services/Logger';
import LoginRedirectionService from '../../services/LoginRedirectionService';
import userInvitationsService, {
	PUBLIC_INVITATON_STATE
} from '../../services/UserInvitationService';
import './EmailInvitationRedirectionPage.scss';
import AuthenticationService from '../../services/AuthenticationService';

const GENERAL_ERROR = 'This account cannot be activated.';

const EmailInvitationRedirectionPage: FC = () => {
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState(null);

	const showError = (): void => {
		setError(GENERAL_ERROR);
		setLoading(false);
	};

	const checkToken = useCallback(async (): Promise<void> => {
		const token = InvitationTokenHelper.getInvitationTokenFromUrl();

		// if user is already logged in, redirect to the invitation activation page
		// this can happen if the user clicks on the invitation link after logging in
		if (AuthenticationService.isLoggedIn()) {
			history.push(RoutesHelper.getInvitationActivationPage(token));
			return;
		}

		if (!token) {
			Logger.error('No invitation token found in the URL', window.location.href);
			showError();
			return;
		}
		const email = InvitationTokenHelper.getEmailFromUrl();
		Logger.info(`Checking user-Invitation: Token: ${token}, Email: ${email}`);
		try {
			const invitationState = await userInvitationsService.getInvitationTokenPublicState(token);
			const emailParam = encodeURIComponent(email);
			const query = `${TOKEN_QUERY_PARAM}=${token}&${EMAIL_QUERY_PARAM}=${emailParam}`;

			let path = null;
			if (invitationState.state === PUBLIC_INVITATON_STATE.SIGNED_UP) {
				path = `${RoutesHelper.getLoginPath()}?${query}`;
			} else {
				path = `${RoutesHelper.getSignupPath()}?${query}`;
			}
			LoginRedirectionService.setRedirectToPath(RoutesHelper.getInvitationActivationPage(token));
			history.push(path);
		} catch (e) {
			Logger.error(
				`EmailInvitationRedirectionPage - Error when validating token ${token} for email ${email}, url: ${window.location.href}`,
				e
			);
			showError();
		}
	}, [history]);

	useEffect(() => {
		checkToken();
	}, [checkToken]);

	const renderContent = (): JSX.Element => {
		if (loading) {
			return (
				<div className="email-invitation-redirection-page">
					<EvSpinner />
				</div>
			);
		}

		// should get here only if there was an error
		return (
			<div className="email-invitation-redirection-page">
				<div className="error-title">Could not activate your account</div>
				<div className="error-body">{error}</div>
				<div className="contact-support">
					For more information, please contact us at <ContactSupportMailLink />
				</div>
			</div>
		);
	};

	return (
		<>
			<Header isAuthenticatedPage={false} />
			<main className="main-section" aria-labelledby={MAIN_TITLE}>
				{renderContent()}
			</main>
		</>
	);
};

export default EmailInvitationRedirectionPage;
