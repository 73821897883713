import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './AppContainer.tsx';
import analytics from './services/analytics/AnalyticsService.ts';
import hubspotService from './services/analytics/HubspotService.ts';
import './index.css';

// Init analytics
analytics.initAnalytics();

// Init hubspot
hubspotService.initHubspotClient();

// eslint-disable-next-line no-undef
console.log(`Current UI Version is: ${UI_VERSION}`);

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<AppContainer />, document.getElementById('root'));
