import {
	BUTTON_TYPES,
	EvIcon,
	EvTableColumn,
	SORT_ORDER,
	EvDropdownMenu,
	DropdownOptionType,
	IDropdownMenuOption,
	FormatHelper,
	EvConfirm,
	TOAST_STATUS
} from '@evinced-private/ui-common';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { PRODUCT_HUB_APP_ID } from '../../consts/dom-consts';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { INotificationsContext } from '../../providers/notificationsProvider/NotificationsContext';
import UserService from '../../services/UserService';
import RoutesHelper from '../../helpers/RoutesHelper';
import { IUserToTenant } from '../../types/User';
import ProductHubTable from '../common/product-hub-table/ProductHubTable';
import EllipsisIcon from '../icons/EllipsisIcon.svg';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { getRegisteredUsersTableColumns } from '../common/helpers/RegisteredUsersHelper';

interface IRegisteredUsersTableProps {
	registeredUsers: IUserToTenant[];
	tableId: string;
	totalCount: number;
	reloadTable: () => void;
	isTableLoading?: boolean;
}

const RegisteredUsersTable: FC<IRegisteredUsersTableProps> = ({
	registeredUsers,
	tableId,
	totalCount,
	isTableLoading,
	reloadTable
}) => {
	const history = useHistory();
	const notificationsContext: INotificationsContext = useNotifications();

	const [showDeactivatePrompt, setShowDeactivatePrompt] = useState<boolean>(false);
	const [isPopupLoading, setIsPopupLoading] = useState<boolean>(false);

	const [deactivatedUser, setDeactivatedUser] = useState<{
		userId: string;
		userName: string;
		userSurname: string;
	}>(null);

	const { usersRolesListDictionary } = useUserTenant();

	const tableRows = useMemo(() => {
		return registeredUsers;
	}, [registeredUsers]);

	const deactivatePromptTitle = useMemo(() => {
		if (deactivatedUser) {
			const { userName = '', userSurname = '' } = deactivatedUser;
			return `Deactivate ${FormatHelper.capitalizeFirstLetter(
				userName
			)} ${FormatHelper.capitalizeFirstLetter(userSurname)}?`;
		}
		return '';
	}, [deactivatedUser]);

	const onUserDeactivation = async (): Promise<void> => {
		try {
			setIsPopupLoading(true);
			await UserService.deactivateUser(deactivatedUser.userId);
			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.SUCCESS,
				announcement: 'User deactivated successfully'
			});
			reloadTable();
		} catch (error) {
			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.FAIL,
				announcement: 'Error deactivating user. Retry'
			});
			setIsPopupLoading(false);
		}
	};

	const renderActionsCell = useCallback(
		(cell, row: IUserToTenant): JSX.Element => {
			// show actions column only for registered status
			if (!row.active) {
				return null;
			}
			const options: IDropdownMenuOption[] = [
				{
					id: 'edit',
					title: 'Edit',
					buttonProps: {
						type: BUTTON_TYPES.ICON,
						onClick: () => {
							history.push({
								pathname: RoutesHelper.getEditUserPath(row.id),
								state: {
									user: row
								}
							});
						},
						children: 'Edit'
					},
					type: DropdownOptionType.BUTTON
				},
				{
					id: 'deactivate',
					title: 'Deactivate',
					buttonProps: {
						type: BUTTON_TYPES.ICON,
						onClick: () => {
							setDeactivatedUser({
								userId: row.id,
								userName: row.user.name,
								userSurname: row.user.surname
							});
							setShowDeactivatePrompt(true);
						},
						children: 'Deactivate'
					},
					disabled: row.role.type === usersRolesListDictionary.TENANT_ADMIN,
					type: DropdownOptionType.BUTTON
				}
			];

			return (
				<div className="actions-cell">
					<div className="actions-cell">
						<EvDropdownMenu
							id={`invitations-management-${row.id}`}
							triggerButtonProps={{
								type: BUTTON_TYPES.ICON,
								ariaHaspopup: 'menu',
								title: `${row.user.email} invitation actions`,
								children: (
									<div className="menu-trigger-icon">
										<EvIcon icon={EllipsisIcon} small />
									</div>
								)
							}}
							options={options}
						/>
					</div>
				</div>
			);
		},
		[history, usersRolesListDictionary]
	);

	const tableColumns = useMemo((): EvTableColumn[] => {
		return [
			...getRegisteredUsersTableColumns(),
			{
				dataField: 'actions',
				text: 'Actions',
				style: { width: '50px' },
				classes: 'actions-cell-td',
				headerStyle: { width: '50px' },
				formatter: renderActionsCell
			}
		];
	}, [renderActionsCell]);

	return (
		<>
			<ProductHubTable
				key={tableId}
				tableId={tableId}
				tableData={tableRows}
				totalCount={totalCount || 0}
				columns={tableColumns}
				isTableLoading={isTableLoading}
				options={{
					title: 'Registered Users',
					pagination: true,
					remote: true,
					dataType: 'users',
					caption: 'registered users table',
					defaultSorted: [{ dataField: 'createdTime', order: SORT_ORDER.DESC }],
					keyField: 'email'
				}}
			/>
			<EvConfirm
				appElement={PRODUCT_HUB_APP_ID}
				title={deactivatePromptTitle}
				approveButtonText="Deactivate"
				promptMessage="Once deactivated, the user will lose access to this account."
				isOpen={showDeactivatePrompt}
				isControlled={true}
				isPopupLoading={isPopupLoading}
				onConfirm={(closeModal) => {
					onUserDeactivation();
					closeModal();
				}}
				onClose={() => {
					setShowDeactivatePrompt(false);
				}}
			/>
		</>
	);
};

export default RegisteredUsersTable;
