import { FC } from 'react';
import { useHistory } from 'react-router';
import InvitationTokenHelper from '../../helpers/InvitationTokenHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import Logger from '../../services/Logger';
import LoginRedirectionService from '../../services/LoginRedirectionService';

const OpenInvitationRedirectionPage: FC = () => {
	const history = useHistory();

	const token = InvitationTokenHelper.getInvitationTokenFromUrl();
	if (token) {
		LoginRedirectionService.setRedirectToPath(RoutesHelper.getInvitationActivationPage(token));
	} else {
		Logger.error('Got to open invitation redirection page, but there is no token in the URL');
	}
	history.push(RoutesHelper.getSignupPath());
	return null;
};

export default OpenInvitationRedirectionPage;
