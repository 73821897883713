/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { IUser, RoleTypeItem } from '../../types/User';
import { ITenant, ProductType, TenantProduct } from '../../types/Tenant';
import UserService from '../../services/UserService';
import TenantsService from '../../services/TenantsService';
import AuthenticationService from '../../services/AuthenticationService';
import UsersService from '../../services/UsersService';

interface IUsersRolesListDictionary {
	[key: string]: string;
}
interface IUserTenant {
	user: IUser;
	tenant: ITenant;
	hasAdminPermissions: () => boolean;
	updateUserTenant: (arg1?: boolean) => Promise<void>;
	usersRolesList: RoleTypeItem[];
	setUsersRolesList: (usersRolesList: RoleTypeItem[]) => void;
	getFullRoleItem: (role: string) => RoleTypeItem;
	usersRolesListDictionary: IUsersRolesListDictionary;
	getProductIdByType: (type: ProductType) => string;
}

const UserTenantContext = createContext<IUserTenant>(null);

const UserTenantProvider: FC = ({ children }) => {
	const [user, setUser] = useState<IUser>(null);
	const [tenant, setTenant] = useState<ITenant>(null);
	const [usersRolesList, setUsersRolesList] = useState<RoleTypeItem[]>([]);
	const [usersRolesListDictionary, setusersRolesListDictionary] =
		useState<IUsersRolesListDictionary>({});

	const getProductIdByType = (productType: ProductType): string => {
		const product = tenant?.products?.find(
			(product: TenantProduct) => product.type === productType
		);
		return product?.id;
	};

	const getFullRoleItem = (role: string): RoleTypeItem => {
		const roleItem = usersRolesList.find((item) => item.type === role);
		return roleItem;
	};

	const updateUserTenant = useCallback(
		async (force = false): Promise<void> => {
			if (AuthenticationService.isLoggedIn()) {
				if (!user || force) {
					const currentUser = await UserService.getUserMetadata();
					const isSSO = AuthenticationService.isUserAuthProviderSaml();
					setUser({ ...currentUser, isSSO });
				}
				if (!tenant || force) {
					const currentTenant = await TenantsService.getUserTenant();
					setTenant(currentTenant);
				}
				const usersRolesList = await UsersService.getRolesList();

				setUsersRolesList(usersRolesList);
			}
		},
		[user, tenant]
	);

	const onLoad = useCallback(() => updateUserTenant(), [updateUserTenant]);

	const hasAdminPermissions = useCallback(
		(): boolean => user && user.role?.type === usersRolesListDictionary?.TENANT_ADMIN,
		[user, usersRolesListDictionary]
	);

	useEffect(() => {
		onLoad();
	}, [onLoad]);

	useEffect(() => {
		const typesListEnum = {};
		usersRolesList.forEach((role) => {
			typesListEnum[role.type] = role.type;
		});
		setusersRolesListDictionary(typesListEnum);
	}, [usersRolesList]);
	return (
		<UserTenantContext.Provider
			value={{
				user,
				tenant,
				hasAdminPermissions,
				updateUserTenant,
				usersRolesList,
				setUsersRolesList,
				getFullRoleItem,
				usersRolesListDictionary,
				getProductIdByType
			}}
		>
			{children}
		</UserTenantContext.Provider>
	);
};

const useUserTenant = (): IUserTenant => useContext(UserTenantContext);

export { UserTenantProvider, useUserTenant };
