import Logger from './Logger';
import API_URL_PATHS from './server/ApiUrlPaths';
import ServerApi from './server/ServerApi';

const { PRODUCT_PLANS_URL_PATH } = API_URL_PATHS;

const getProductPlanDisplayName = async (planId: string): Promise<string> => {
	let result = '';
	try {
		const plan = await ServerApi.apiCall('GET', `${PRODUCT_PLANS_URL_PATH}/${planId}`);
		result = plan.displayName;
	} catch (error) {
		Logger.error(error);
	}
	return result;
};

export default {
	getProductPlanDisplayName
};
