import { IOpenInvitation } from 'src/types/OpenInvitation';
import { IUserInvitation } from '../types/UserInvitation';
import INVITATION_STATE from '../types/UserInvitationState';
import API_URL_PATHS from './server/ApiUrlPaths';
import ServerApi from './server/ServerApi';

const { TENANTS_URL_PATH, USER_INVITATIONS_URL_PATH, OPEN_INVITATIONS_URL_PATH } = API_URL_PATHS;

const states = 'PENDING,EXPIRED';
const cachedInvitations: IOpenInvitation[] = [];

async function getInvitation(tenantId: string, invitationId: string): Promise<IUserInvitation> {
	return ServerApi.apiCall(
		'GET',
		`${TENANTS_URL_PATH}/${tenantId}/user-invitations/${invitationId}`
	);
}

async function createInvitation(
	email: string,
	firstname: string,
	lastname: string,
	tenantId: string,
	roleType: string
): Promise<IUserInvitation> {
	const body = {
		targetName: firstname,
		targetSurname: lastname,
		targetEmail: email,
		roleType
	};
	return ServerApi.apiCall('POST', `${TENANTS_URL_PATH}/${tenantId}${USER_INVITATIONS_URL_PATH}`, {
		data: body
	});
}

async function revokeInvitation(inviteId: string, tenantId: string): Promise<IUserInvitation> {
	return ServerApi.apiCall(
		'DELETE',
		`${TENANTS_URL_PATH}/${tenantId}${USER_INVITATIONS_URL_PATH}/${inviteId}`
	);
}

async function getAllInvitations(tenantId: string): Promise<Array<IUserInvitation>> {
	return ServerApi.apiCall(
		'GET',
		`${TENANTS_URL_PATH}/${tenantId}/user-invitations?states=${states}`
	);
}

export enum PUBLIC_INVITATON_STATE {
	SIGNED_UP = 'SIGNED_UP',
	NOT_SIGNED_UP = 'NOT_SIGNED_UP'
}
type PublicStateResponse = {
	state: PUBLIC_INVITATON_STATE;
};
async function getInvitationTokenPublicState(
	invitationToken: string
): Promise<PublicStateResponse> {
	return ServerApi.apiCall(
		'GET',
		`${USER_INVITATIONS_URL_PATH}/public-state?token=${invitationToken}`
	);
}
async function getAllUserPendingInvitations(): Promise<IUserInvitation[]> {
	const invitations = await ServerApi.apiCall('GET', USER_INVITATIONS_URL_PATH);
	return invitations.filter((i: IUserInvitation) => i.state === INVITATION_STATE.PENDING);
}

async function acceptInvitation(invitationToken: string): Promise<IUserInvitation> {
	return ServerApi.apiCall('POST', `${USER_INVITATIONS_URL_PATH}/accept?token=${invitationToken}`);
}

async function acceptOpenInvitation(invitationToken: string): Promise<IUserInvitation> {
	return ServerApi.apiCall('POST', `${OPEN_INVITATIONS_URL_PATH}/connect?token=${invitationToken}`);
}

async function getOpenInvitationDetails(invitationToken: string): Promise<IOpenInvitation> {
	return ServerApi.apiCall('GET', `${OPEN_INVITATIONS_URL_PATH}/state?token=${invitationToken}`);
}

async function getOpenInvitationsDetails(): Promise<IOpenInvitation[]> {
	if (cachedInvitations.length) {
		return cachedInvitations;
	}
	const result = await ServerApi.apiCall('GET', OPEN_INVITATIONS_URL_PATH);
	if (cachedInvitations.length === 0) {
		cachedInvitations.push(...result);
	}
	return result;
}

function isOpenInvitationExpired(invitationDetails: IOpenInvitation): boolean {
	return new Date() > new Date(invitationDetails.expirationTime);
}

const userInvitationsService = {
	getInvitation,
	createInvitation,
	revokeInvitation,
	getAllInvitations,
	getInvitationTokenPublicState,
	acceptInvitation,
	getAllUserPendingInvitations,

	// open invitation
	getOpenInvitationDetails,
	getOpenInvitationsDetails,
	isOpenInvitationExpired,
	acceptOpenInvitation
};
export default userInvitationsService;
