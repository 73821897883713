import { withAuthenticationRequired } from '@auth0/auth0-react';
import { EvSpinner } from '@evinced-private/ui-common';
import React from 'react';
import InvitationTokenHelper from '../../helpers/InvitationTokenHelper';
import AuthToken from './AuthToken';

export default withAuthenticationRequired(AuthToken, {
	onRedirecting: () => {
		return <EvSpinner />;
	},
	loginOptions: {
		// passing this parameter to the signup screen so it will no show the signup option
		appState: { 'ext-hidesignup': InvitationTokenHelper.shouldHideSignupOption() }
	}
});
