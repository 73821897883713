import React, { FC, useEffect, useState } from 'react';
import {
	EvButton,
	BUTTON_TYPES,
	EvSpinner,
	EvCard,
	EvLink,
	EvSection,
	EvIcon,
	EvTitle,
	EvTextInput,
	BUTTON_SIZES,
	EvContactUsButton,
	useTitle,
	EvMainTitle, copyTextToClipboard
} from '@evinced-private/ui-common';
import AppHelper from 'src/helpers/AppHelper';
import DateFormatHelper from '../../components/common/helpers/DateFormatHelper';
import CopyIcon from '../../components/icons/CopyIcon.svg';
import EXTERNAL_LINKS from '../../helpers/ExternalLinks';
import Logger from '../../services/Logger';
import ServiceAccountService from '../../services/ServiceAccountService';
import { hubspotAPIKey } from '../../services/analytics/HubspotService';
import TenantsService, { TenantExpirationDetails } from '../../services/TenantsService';
import { ProductType } from '../../types/Tenant';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import './WebSdkPage.scss';

const WebSDKPage: FC = () => {
	const [isLoading, setLoading] = useState(true);
	const [productExpirationDetails, setProductExpirationDetails] =
		useState<TenantExpirationDetails>();
	const [serviceAccountDetails, setServiceAccountDetails] = useState<{
		secret: string;
		id: string;
	}>();

	const { setTitle } = useTitle();
	setTitle(AppHelper.getPageTitle(''), 'Web SDK');
	const { user, tenant } = useUserTenant();

	useEffect(() => {
		const getDownloadLinks = async (): Promise<void> => {
			setLoading(true);
			try {
				// check token
				const expirationDate = await TenantsService.getProductExpirationDetails(
					ProductType.WEB_SDK
				);
				setProductExpirationDetails(expirationDate);
				if (!expirationDate.isExpired) {
					const serviceAccount = await ServiceAccountService.getServiceAccountDetails();
					setServiceAccountDetails({ id: serviceAccount?.id, secret: serviceAccount?.secret });
				}
			} catch (err) {
				Logger.error('Error getting service account or expiration details');
			} finally {
				setLoading(false);
			}
		};
		getDownloadLinks();
	}, []);

	if (isLoading) {
		return (
			<div className="web-sdk-page">
				<EvSpinner />
			</div>
		);
	}

	const renderDownloadWebCardFooter = (title: string, link: string): JSX.Element => {
		return (
			<div className="web-automation-card-footer">
				<EvButton
					title={title}
					type={BUTTON_TYPES.PRIMARY}
					onClick={() => window.open(link, '_blank')}
					disabled={productExpirationDetails.isExpired}
					size={BUTTON_SIZES.BIG}
				>
					Get Started
				</EvButton>
			</div>
		);
	};

	const cards = [
		{
			title: 'Cypress SDK',
			content: 'For JavaScript testing',
			link: EXTERNAL_LINKS.CYPRESS_SDK_DEVELOPER_DOCS_LINK
		},
		{
			title: 'Selenium Java SDK',
			content: 'For testing with JUnit, TestNG or Cucumber on Java',
			link: EXTERNAL_LINKS.SELENIUM_SDK_DEVELOPER_DOCS_LINK
		},
		{
			title: 'Selenium C# SDK',
			content: 'For C# testing',
			link: EXTERNAL_LINKS.SELENIUM_CS_SDK_DEVELOPER_DOCS_LINK
		},
		{
			title: 'Selenium JS SDK',
			content: 'For JavaScript testing',
			link: EXTERNAL_LINKS.SELENIUM_JS_SDK_LINK
		},
		{
			title: 'Playwright JS SDK',
			content: 'For JavaScript testing',
			link: EXTERNAL_LINKS.PLAYWRIGHT_JS_SDK_LINK
		},
		{
			title: 'Playwright Java SDK',
			content: 'For testing with Playwright runner, JUnit, TestNG or Cucumber on Java',
			link: EXTERNAL_LINKS.PLAYWRIGHT_JAVA_SDK_LINK
		},
		{
			title: 'WebdriverIO SDK',
			content: 'For JavaScript testing',
			link: EXTERNAL_LINKS.WEBDRIVERIO_SDK_DEVELOPER_DOCS_LINK
		},
		{
			title: 'TestCafe SDK',
			content: 'For JavaScript testing',
			link: EXTERNAL_LINKS.TESTCAFE_SDK_LINK
		}
	];

	const renderTextBoxWithCopyButton = (
		fieldDescriptionTitle: string,
		value: string,
		buttonTitle: string
	): JSX.Element => {
		return (
			<div className="copy-key-info">
				<div className="line1">{fieldDescriptionTitle}</div>
				<div className="copy-text">
					<EvTextInput isDisabled value={value} onChange={() => null} className="copy-value" />
					<EvButton
						type={BUTTON_TYPES.ICON}
						title={buttonTitle}
						onClick={() => copyTextToClipboard(value, fieldDescriptionTitle)}
					>
						<EvIcon icon={CopyIcon} />
					</EvButton>
				</div>
			</div>
		);
	};

	const renderKeysSection = (): JSX.Element => {
		return (
			<EvSection className="api-key-section">
				<div className="key-info">
					<EvTitle className="key-section-title" titleText="Get your API key" />
					<div className="sub-text">
						{productExpirationDetails.isLicensedForever
							? 'Your license is active'
							: `Your license will expire on ${DateFormatHelper.formatDate(productExpirationDetails.date)}`}
					</div>
				</div>
				{renderTextBoxWithCopyButton(
					'Service Account ID',
					serviceAccountDetails.id,
					'Copy Service Account ID'
				)}
				{renderTextBoxWithCopyButton('API Key', serviceAccountDetails.secret, 'Copy API Key')}
			</EvSection>
		);
	};

	const renderExpiredNotice = (): JSX.Element => {
		return (
			<EvSection className="api-key-section trial-ended">
				<EvContactUsButton
					userEmail={user?.email}
					userTenant={tenant?.id}
					portalId={hubspotAPIKey}
					formTitle="Would you like to renew your license?"
					product="Automation for Web"
				/>
				<div className="error-message">Your license has ended</div>
			</EvSection>
		);
	};
	return (
		<div className="web-sdk-page">
			<div className="web-automation-content">
				<EvMainTitle>Welcome to Evinced Test Automation SDKs for Web apps!</EvMainTitle>
				<p className="sub-title">
					Read installation guidelines per test framework via the links below.<br />
					Please note the SDK files are stored in a private JFrog repository.
					Contact us or your account admin for access.
				</p>
				<EvSection className="cards-section">
					{cards.map((cardInfo) => (
						<EvCard
							key={cardInfo.link}
							className="web-automation-card"
							title={cardInfo.title}
							footer={renderDownloadWebCardFooter(cardInfo.title, cardInfo.link)}
						>
							{cardInfo.content}
						</EvCard>
					))}
				</EvSection>
				{productExpirationDetails.isExpired ? renderExpiredNotice() : renderKeysSection()}
				<div className="more-info">
					For a quick overview of how SDKs work, please visit the&nbsp;
					<EvLink
						url={EXTERNAL_LINKS.LEARN_MORE_WEB_AUTOMATION_LINK}
						linkText="Automation for Web"
						openInNewTab
					/>
					&nbsp;page at Evinced.com.
				</div>
			</div>
		</div>
	);
};

export default WebSDKPage;
