import { EvTableColumn, FormatHelper } from '@evinced-private/ui-common';
import { IUserInvitation, ROLE_TYPE_LABELS } from '../../../types/UserInvitation';
import { USER_STATUS } from '../../../types/User';
import { formatDate } from './DateFormatHelper';

export const isValidEmail = (emailStr = ''): boolean => {
	const validMailRegExp =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return !!emailStr.match(validMailRegExp);
};

export const getUserStatus = (active: boolean): string => {
	return active ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE;
};

export const getInvitationsTableColumns = (getFullRoleItem): EvTableColumn[] => [
	{
		dataField: 'email',
		text: 'Email',
		style: { width: '200px' },
		headerStyle: { width: '200px', maxWidth: '200px' },
		headerClasses: 'filter-cell',
		sort: true
	},
	{
		dataField: 'createdTime',
		text: 'Created Time',
		style: { width: '200px' },
		headerStyle: { width: '200px' },
		headerClasses: 'filter-cell',
		formatter: (cell) => {
			return formatDate(cell);
		},
		sort: true
	},
	{
		dataField: 'status',
		text: 'Status',
		style: { width: '100px' },
		headerStyle: { width: '100px' },
		headerClasses: 'filter-cell',
		formatter: (cell) => {
			return FormatHelper.capitalizeFirstLetter(cell.toLowerCase());
		},
		sort: true
	},
	{
		dataField: 'userType',
		text: 'User type',
		style: { width: '100px' },
		headerStyle: { width: '100px' },
		headerClasses: 'filter-cell',
		formatter: (cell) => {
			return getFullRoleItem(cell).name;
		},
		sort: true
	}
];

export const getColumnsFormatters = (): { [key: string]: (row: IUserInvitation) => string } => ({
	email: (row: IUserInvitation) => {
		return row.targetEmail;
	},
	status: (row: IUserInvitation) => {
		return FormatHelper.capitalizeFirstLetter(row.state.toLowerCase());
	},
	userType: (row: IUserInvitation) => {
		return FormatHelper.capitalizeFirstLetter(ROLE_TYPE_LABELS[row.roleType].toLowerCase());
	}
});
