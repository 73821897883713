/* eslint-disable react/jsx-no-constructed-context-values */
import { IEvBreadcrumb } from '@evinced-private/ui-common';
import React, { createContext, FC, useContext, useState } from 'react';

interface INavigation {
	currentBreadcrumbs: IEvBreadcrumb[];
	setCurrentBreadcrumbs: (breadcrumbs: IEvBreadcrumb[]) => void;
}

const NavigationContext = createContext<INavigation>(null);

const NavigationProvider: FC = ({ children }) => {
	const [currentBreadcrumbs, setCurrentBreadcrumbs] = useState<IEvBreadcrumb[]>(null);

	return (
		<NavigationContext.Provider value={{ currentBreadcrumbs, setCurrentBreadcrumbs }}>
			{children}
		</NavigationContext.Provider>
	);
};

const useNavigation = (): INavigation => useContext(NavigationContext);

export { NavigationProvider, useNavigation };
