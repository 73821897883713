/* eslint-disable react/jsx-no-constructed-context-values */
import _ from 'lodash';
import React, { FC, createContext, useState, useContext, useEffect, useCallback } from 'react';
import { SORT_ORDER, OptionType } from '@evinced-private/ui-common';
import LocalStorageApi from '../../api/LocalStorageApi';
import Logger from '../../services/Logger';

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE_PER_PAGE = 10;
const DEFAULT_TABLE_STATE = {
	filters: null,
	tableSort: null,
	paginationInfo: {
		page: DEFAULT_PAGE,
		sizePerPage: DEFAULT_SIZE_PER_PAGE
	}
};

const TABLES_STATE = 'TABLES_STATE';

interface ITablePagination {
	page: number;
	sizePerPage: number;
}

export interface ITableSortOption {
	dataField: string;
	order: SORT_ORDER;
}

/* Since we don't have a mutual structure for our tables , we need to have a
   generic structure for the filters state */
export interface IGenericFilterOptions {
	[filter: string]: OptionType[] | string;
}

export interface ITableStateValue {
	filters: IGenericFilterOptions;
	tableSort: Array<ITableSortOption>;
	paginationInfo: ITablePagination;
}

type ITableState = {
	[id: string]: ITableStateValue;
};

interface ITableStateContext {
	tablesState: ITableState;
	updateTableState: (tableId: string, newStateValue: ITableStateValue) => void;
	getTableStateById: (tableId: string) => ITableStateValue;
	onTablePageOrPageSizeChange: (tableId: string, pagination: ITablePagination) => void;
	onTableSort: (tableId: string, sort: ITableSortOption[]) => void;
	onFilterChange: (tableId: string, filters: IGenericFilterOptions) => void;
}

const TableStateContext = createContext({} as ITableStateContext);
export const useTableState = (): ITableStateContext => useContext(TableStateContext);

export const TableStateProvider: FC<{ state: ITableState }> = ({ state, children }) => {
	const [tablesState, setTableState] = useState<ITableState>(state);

	useEffect(() => {
		const savedTablesState = async (): Promise<void> => {
			const tableStateFromStorageString = await LocalStorageApi.getFromLocalStorage(TABLES_STATE);
			if (tableStateFromStorageString) {
				const tableStateFromStorage = JSON.parse(tableStateFromStorageString);
				if (tableStateFromStorage) {
					setTableState(tableStateFromStorage);
				}
			}
		};

		try {
			savedTablesState();
		} catch (error) {
			Logger.error(error);
		}
	}, []);
	const updateTableState = useCallback(
		(tableId: string, newStateValue: ITableStateValue): void => {
			const updatedState = _.cloneDeep(tablesState);
			updatedState[tableId] = newStateValue;
			setTableState(updatedState);
		},
		[tablesState, setTableState]
	);

	const getTableStateById = useCallback(
		(tableId: string): ITableStateValue => {
			const currentState = _.cloneDeep(tablesState);
			return currentState[tableId] || (DEFAULT_TABLE_STATE as ITableStateValue);
		},
		[tablesState]
	);

	const onTablePageOrPageSizeChange = useCallback(
		(tableId: string, { page, sizePerPage }): void => {
			const tableState = getTableStateById(tableId);
			const paginationInfo = { page, sizePerPage };

			const newState = { ...tableState, paginationInfo };
			updateTableState(tableId, newState);
		},
		[getTableStateById, updateTableState]
	);

	const onTableSort = useCallback(
		(tableId: string, sort: ITableSortOption[]): void => {
			const tableState = getTableStateById(tableId);
			const newState = { ...tableState, tableSort: sort };
			updateTableState(tableId, newState);
		},
		[getTableStateById, updateTableState]
	);

	const onFilterChange = useCallback(
		(tableId: string, filters: IGenericFilterOptions): void => {
			const tableState = getTableStateById(tableId);
			const paginationInfo = { ...tableState.paginationInfo, page: 1 };
			const newState = { ...tableState, filters, paginationInfo };

			updateTableState(tableId, newState);
		},
		[getTableStateById, updateTableState]
	);

	useEffect(() => {
		if (Object.keys(tablesState).length) {
			LocalStorageApi.saveToLocalStorage(TABLES_STATE, JSON.stringify(tablesState));
		}
	}, [tablesState]);

	return (
		<TableStateContext.Provider
			value={{
				tablesState,
				getTableStateById,
				updateTableState,
				onTablePageOrPageSizeChange,
				onTableSort,
				onFilterChange
			}}
		>
			{children}
		</TableStateContext.Provider>
	);
};
