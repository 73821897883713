import { IServerError } from '../services/server/ServerError';

const UNALLOWED_DOMAIN_TEXT = 'is not in your allowed domains list';
const DOMAIN_NOT_ON_USER_LIST = 'is not in your allowed domains list (';

const getErrorMessage = (serverError: IServerError): string | null => {
	return serverError?.error?.message || `Response status ${serverError?.response?.status}`;
};

// eg. linkedin
const isGloballyUnallowedDomainError = (error: IServerError): boolean => {
	const message = getErrorMessage(error) || '';
	return message && message.includes(UNALLOWED_DOMAIN_TEXT);
};

// eg. domain is not the same as company email
const isDomainNotOnUserListError = (error: IServerError): boolean => {
	const message = getErrorMessage(error);
	return message && message.includes(DOMAIN_NOT_ON_USER_LIST);
};

const isErrorRelatedToDomains = (error): boolean => {
	const unAllowedDomainError = isGloballyUnallowedDomainError(error);
	const domainNotOnUserListError = isDomainNotOnUserListError(error);
	return unAllowedDomainError || domainNotOnUserListError;
};

/**
 * returns true if the error code is 4XX
 * @param serverError
 */
const isClientError = (serverError: IServerError): boolean => {
	const status = serverError?.response?.status;
	return status < 500 && status >= 400;
};

export default {
	isGloballyUnallowedDomainError,
	isDomainNotOnUserListError,
	isErrorRelatedToDomains,
	isClientError,
	getErrorMessage
};
