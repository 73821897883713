import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import RoutesHelper from '../../helpers/RoutesHelper';
import authenticationService from '../AuthenticationService';
import Logger from '../Logger';
import { IServerError } from './ServerError';

function apiCall(
	method: Method,
	url: string,
	options?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data?: any;
		isAbsoluteUrl?: boolean;
		noExtraHeaders?: boolean;
		isFile?: boolean;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
	const checkStatus = (response): AxiosResponse => {
		// Raises an error in case response status is not a success
		if (response.status >= 200 && response.status < 300) {
			// Success status lies between 200 to 300
			return response;
		}

		const errorObject: IServerError = {
			error: new Error(response.statusText),
			response
		};
		throw errorObject;
	};

	const cleanError = (error: AxiosError): AxiosError => {
		delete error?.config?.headers;
		delete error?.response?.headers;
		return error;
	};

	const checkError = (error): void => {
		Logger.error('Request failed with server error:', cleanError(error));
		// Log user out in case the API returned unauthorized (401)
		if (error.response && error.response.status === 401) {
			// logout occurs flow ends here
			authenticationService.logout();
		} else if (error.response && error.response.status === 403) {
			// redirect occurs flow ends here
			window.location.href = RoutesHelper.getUnauthorized403Page();
		} else {
			// throws error
			const errorObject: IServerError = {
				error: new Error(
					error?.response?.data ? error.response.data.message : error.message
				),
				response: error.response
			};
			throw errorObject;
		}
	};

	// Performs api calls sending the required authentication headers
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let headers: any = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-ID': 'UI' // custom field to know a request was generated by ui
	};

	// Setting Authorization header if logged in
	// Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
	if (authenticationService.isLoggedIn()) {
		headers.Authorization = `Bearer ${authenticationService.getAccessToken()}`;
	}

	// support case where the URL is not under /api
	const baseURL = options && options.isAbsoluteUrl ? '' : process.env.API_URL;

	// support case where there shouldn't be authentication and content type headers
	headers = options && options.noExtraHeaders ? {} : headers;
	const data = options && options.data ? options.data : null;

	return axios({
		method,
		url,
		baseURL,
		headers,
		data
	})
		.then(checkStatus)
		.then((response) => {
			return response.data;
		})
		.catch(checkError);
}

export default { apiCall };
