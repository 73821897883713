import API_URL_PATHS from './server/ApiUrlPaths';
import ServerApi from './server/ServerApi';
import { IUser, IUserToTenant } from '../types/User';

const { USER_URL_PATH, USERS_URL_PATH } = API_URL_PATHS;

async function getUserMetadata(): Promise<IUser> {
	return ServerApi.apiCall('GET', USER_URL_PATH);
}

async function updateUserData(userData: IUserToTenant): Promise<IUserToTenant> {
	const url = `${USERS_URL_PATH}/${userData.id}`;
	const response = await ServerApi.apiCall('PUT', url, { data: userData });
	return response;
}

async function deactivateUser(userId: string): Promise<void> {
	const url = `${USERS_URL_PATH}/${userId}`;
	return ServerApi.apiCall('DELETE', url, { data: userId });
}

const resetPassword = async (userEmail: string): Promise<void> => {
	return ServerApi.apiCall(
		'POST',
		`https://${process.env.AUTH0_EVINCED_DOMAIN}/dbconnections/change_password`,
		{
			noExtraHeaders: true,
			isAbsoluteUrl: true,
			data: {
				client_id: process.env.AUTH0_CLIENT_ID,
				email: userEmail,
				connection: 'Username-Password-Authentication'
			}
		}
	);
};

export default { getUserMetadata, updateUserData, deactivateUser, resetPassword };
