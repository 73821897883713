import { OptionType } from '@evinced-private/ui-common';
import { isEqual } from 'lodash';
import { PaginationParams } from '../types/PaginationParams';
import { IGenericFilterOptions } from '../providers/tableStateProvider/TableStateProvider';
import { RoleTypeItem, USER_STATUS } from '../types/User';

export interface IFilterOptions extends IGenericFilterOptions {
	userType: OptionType[];
	active: OptionType[];
	freeSearch: string;
}

export const areFiltersEqual = (
	filters: IFilterOptions,
	selectedFilters: IFilterOptions
): boolean => {
	if (!filters || !selectedFilters) {
		return false;
	}
	return (
		!!Object.keys(selectedFilters)?.length &&
		Object.keys(filters).every((key) => {
			if (filters[key] === 'string') {
				return filters[key] === selectedFilters[key];
			}
			return isEqual(filters[key], selectedFilters[key]);
		})
	);
};

export const getAllStatusOptions = (): OptionType[] => {
	return [
		{
			label: USER_STATUS.ACTIVE,
			value: 'true'
		},
		{
			label: USER_STATUS.INACTIVE,
			value: 'false'
		}
	];
};

// Currently this list will be static in the UI until the BE will be ready
export const getAllUserTypes = (usersTypes: RoleTypeItem[]): OptionType[] => {
	return usersTypes.map((type) => {
		return {
			label: type.name,
			value: type.type
		};
	});
};

const createSortUrlParams = (paginationParams?: PaginationParams): string => {
	if (!paginationParams) {
		return '';
	}
	const { page, sizePerPage, sortField, sortOrder } = paginationParams;
	let params = '';
	if (page) {
		params += `&pageNumber=${page - 1}`;
	}
	if (sizePerPage) {
		params += `&pageSize=${sizePerPage}`;
	}
	if (sortField) {
		params += `&sortField=${sortField}`;
	}
	if (sortOrder) {
		params += `&sortDirection=${sortOrder.toUpperCase()}`;
	}
	return params;
};
const formatFiltersForUrl = (
	filters: IFilterOptions,
	filtersOptions: IFilterOptions
): string => {
	if (!filters) {
		return '';
	}

	const filterParams = [];

	Object.keys(filters).forEach((key) => {
		/** The meaning is that filters values either has the 'select all' option
		 *  or the length of the array is equal to the length of the options array.
		 * In that case, we do not need to send the filter to the backend.
		 */

		if (Array.isArray(filters[key])) {
			if (filters[key].length >= filtersOptions[key].length) {
				return;
			}
			const filterValues = (filters[key] as OptionType[]).map((filter) => filter.value);

			filterParams.push(`${key}=${filterValues.join(',')}`);
		} else {
			if (!filters[key]) {
				return;
			}
			filterParams.push(`${key}=${encodeURIComponent(filters[key] as string)}`);
		}
	});
	return filterParams.join('&');
};

export const createUrlWithFiltersAndPaginationParams = (
	filters: IFilterOptions,
	filtersOptions: IFilterOptions,
	paginationParams: PaginationParams
): string => {
	const sortParams = createSortUrlParams(paginationParams);
	const filterParams = formatFiltersForUrl(filters, filtersOptions);
	const filteredParamsUrl = `?${filterParams}${sortParams}`.replace(/\?&/, '?');
	return `${filteredParamsUrl}`;
};
