import React from 'react';
import { EvTableColumn } from '@evinced-private/ui-common';
import { IUserToTenant } from 'src/types/User';
import { formatDate } from './DateFormatHelper';
import { getUserStatus } from './InviteUsersHelper';

export const getRegisteredUsersTableColumns = (): EvTableColumn[] => [
	{
		dataField: 'userName',
		text: 'Name',
		style: { width: '200px' },
		headerStyle: { width: '200px', maxWidth: '200px' },
		headerClasses: 'filter-cell',
		formatter(_, row: IUserToTenant) {
			return <div>{`${row.user.name || ''} ${row.user.surname || ''}`}</div>;
		},
		sort: true
	},
	{
		dataField: 'userEmail',
		text: 'Email',
		style: { width: '200px' },
		headerStyle: { width: '200px', maxWidth: '200px' },
		formatter(_, row: IUserToTenant) {
			return <div>{row.user.email}</div>;
		},
		headerClasses: 'filter-cell',
		sort: true
	},
	{
		dataField: 'userType',
		text: 'User type',
		style: { width: '100px' },
		headerStyle: { width: '100px' },
		headerClasses: 'filter-cell',
		formatter: (_, field: IUserToTenant) => {
			return field.role.name;
		},
		sort: true
	},
	{
		dataField: 'createdTime',
		text: 'Created Time',
		style: { width: '200px' },
		headerStyle: { width: '200px' },
		headerClasses: 'filter-cell',
		formatter: (cell: string) => {
			return formatDate(cell);
		},
		sort: true
	},
	{
		dataField: 'lastLogin',
		text: 'Last Login',
		style: { width: '200px' },
		headerStyle: { width: '200px' },
		headerClasses: 'filter-cell',
		formatter: (_, field: IUserToTenant) => {
			const { lastLogin } = field.user;
			return lastLogin ? formatDate(lastLogin) : 'N/A';
		},
		sort: true
	},
	{
		dataField: 'active',
		text: 'Status',
		style: { width: '100px' },
		headerStyle: { width: '100px' },
		headerClasses: 'filter-cell',
		formatter: (cell: boolean) => {
			return getUserStatus(cell);
		},
		sort: true
	}
];

export const getColumnsFormatters = (): { [key: string]: (row: IUserToTenant) => string } => ({
	userName: (row: IUserToTenant) => {
		return `${row.user?.name || ' '} ${row.user?.surname || ''}`;
	},
	userEmail: (row: IUserToTenant) => {
		return row.user?.email;
	},
	userType: (row: IUserToTenant) => {
		return row.role?.name;
	},
	active: (row: IUserToTenant) => {
		return row.active ? 'Active' : 'Inactive';
	}
});
